.about{
    /* margin-top: -115px; */
    margin-bottom: 230px;  
    background: linear-gradient(180deg, rgba(218, 220, 222, 0) 7.54%, rgba(218, 220, 222, 0.4) 32.59%, rgba(218, 220, 222, 0.5) 63.98%, rgba(218, 220, 222, 0) 100%);  
}
.aboutSection {
    margin-bottom: 70px;
}

.about img{
    margin: auto;
    margin-bottom: 40px;
    width: 85vw;
    max-width: 600px;
    border-radius: 16px;
    object-fit: cover;
}
.about .textAbout{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 20px;
    margin: 16px;
    margin-top: 0;
    color: #1A1A1A;
}

.about .textAbout .title{
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 8px;
    letter-spacing: 0.06em;
}
.about .textAbout .subtitle{
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 52px;
}

.about .textAbout .link p{
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    text-align: left;
    letter-spacing: 0.01em;
    color:#56E1FF;
    width: 100%;    
    margin-top: 20px;
    font-size: 16px;
    line-height: 20px;
    background: linear-gradient(106.12deg, rgba(169, 1, 1, 0.95) 22.42%, #BA000E 77.58%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}
.about .textAbout .link img{
    height: 10px;
    width: 15px;
    margin: 0;
    margin-left: 13px;
    display: inline-block;
}
.about .textAbout .text{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.06em;
    color: #808080;
}
.nuestroEquipo{
}

.nuestroEquipo div{
    display: flex;
    flex-direction: column;
}
.nuestroEquipo div img{
    margin: auto;
    margin-bottom: 80px;
    width: 100%;
    height: auto;
}

@media (max-width:800px) {
    .about{
        margin-bottom: 100px;
        margin-top:0;
    }
    .aboutSection img.img3 , .aboutSection img.img2{
        margin-bottom: 80px;
    }
}
@media (min-width: 1000px){
    .nuestroEquipo div{
        display: flex;
        flex-direction: row;
    }
    .nuestroEquipo div img{
        width: 48%;        
    }
    .about img{
        border-radius: 16px;
        object-fit: cover;
        margin: 0;
    }
    .about img.img1{
        width: 45%;
        max-width: 550px;
        height: 523px;
    }
    .about img.img2{
        width: 45%;
        height: 380px;
    }
    .about img.img3{
        width: 45%;
        height: 442px;
        
    }
    .about .textAbout .title{
        font-size: 24px;  
        line-height: 31px;
    }
    .about .textAbout .text{
        font-size: 16px;
        line-height: 24px;
    }
    .aboutSection {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        gap: 30px;
        margin: 130px 8vw;
        align-items: center;
    }
    .aboutSection:nth-child(even) {
        flex-direction: row-reverse;
    }
    .about .textAbout{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: 20px;
        width: 45%;
        max-width: 520px;
    }

    .nuestroEquipo p{
        font-weight: 700;
        font-size: 40px;
        line-height: 60px;
        margin-bottom: 206px;
    }


}
@media (min-width: 1200px) {
    .nuestroEquipo div{
        display: flex;
        gap: 22px;
    }
    .nuestroEquipo{
        padding: 200px 160px 240px 160px;
    }

}

@media (min-width: 1400px) {
    .about img{
        max-width: 700px;
        height: 600px;
    }
    .about .textAbout .text{
        font-size: 20px;
    }
    .about .textAbout .link p{
        font-size: 20px;
    }

}

@media (min-width: 1600px) {
    .aboutSection{
        margin: 10vh 10vw;
    }
    .about .textAbout .title{
        font-size: 28px;
    }
    .about img.img1{
        height: 525px;
        width: 515px;
    }
    .about img.img2{
        height: 400px;
        width: 620px;
    }
    .about img.img3{
        height: 530px;
        width: 620px;
    }
    .about .textAbout .text{
        font-size: 20px;
    }
    .about .textAbout .link p{
        font-size: 20px;
    }
}