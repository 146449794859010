.accordionTitle{
    font-family: 'Ubuntu';
    font-style: normal;   
    display: flex;
    align-items: center;
    gap: 24px;
    color: #4D4D4D ;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
}
.accordionTitle p{
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #DA1C2B;

}
.disclosure {
    border-bottom:1px solid #d1d2d2;
}

.accordionDescription .detailsText{
    margin-bottom: 10px;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
    color: #808080;
    margin-left: 40px;
}
@media(min-width:1600px){
    .accordionTitle{
        font-size: 20px;
        font-weight: 500;
    }
    .accordionDescription .detailsText{
        font-size: 18px;
    }
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root{
    box-shadow: none !important;    
}

.expandContainer{
    height: 300px;
    overflow-y: scroll;
}
@media(max-width:1100px){
    .expandContainer{    
        width: 80%;
        max-width: 700px;
        margin: auto;
    }
}

