.productContainer {    
    /*
    padding: 30px;
    */
}

.backgroundDegrade{
    display: block;
    
    background: linear-gradient(180deg, rgba(218, 220, 222, 0) 7.54%, rgba(218, 220, 222, 0.4) 32.59%, rgba(218, 220, 222, 0.5) 63.98%, rgba(218, 220, 222, 0) 100%), url("../../../public/assets/bg-product-2.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
}

.productContainer .productTitle{
    text-align: center;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    margin-bottom: 80px;
}

.productContainer .productSubtitle{
    text-align: center;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.06em;
    text-transform: uppercase;    
    margin-top: 190px;
}

.productContainer .productDetails{
    display: flex;
    flex-direction: column;
    margin: 16px;
}

.accordionContainer{
    width: 100%;
    max-width: 700px;
    margin: auto;
}

.accordionContainer .detailsText{
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    display: flex;
    align-items: center;
    letter-spacing: 0.06em;
    margin-bottom: 85px;
    color: #FCFCFC;
}


#swiperImgProduct{
    margin: 50px 0;
}
.productContainer .titles{
    display: none;
}
.productContainer .subtitle {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #FF5B68;
    margin-bottom: 16px;
}

.productContainer .detailsTitle{
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.06em;
    color: #1A1A1A;
    max-width: 900px;
}
@media (min-width: 700px) {
    .productContainer .titles .subtitle,.productContainer .titles .detailsTitle{
        text-align: left;
    }

}
@media (min-width: 1100px){
    .productContainer .productDetails{
        flex-direction: row;
        flex-wrap: wrap;        
        gap: 30px;
        justify-content: space-evenly;
    }
    #swiperImgProduct{
        width: 35%;
       height: 35%;
    }
    .productContainer .titlesMobile{
        display: none;
    }
    .productContainer .titles{
        display: inline-block;
        width: 100%;
        max-width: 800px;
        margin-bottom: 40px;
    }
    .accordionContainer{
        margin: 0;
        width: 510px;  
        margin-top: 0;  
    }
}

@media (min-width:1600px){
    .productContainer .productDetails{
        max-width: 2000px;
        margin: auto;
    }
    .productContainer .detailsTitle {
        font-size: 22px;
    }
    .productContainer .subtitle{
        font-size: 20px;
    }

}
