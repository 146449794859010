.strengths{
  margin: auto;
  width: 80%;
  max-width: 1100px;
  padding-top: 60px;
}

.bannerProductsStrength{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.bannerProductsStrength .strengths-card{
  width: 250px;  
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 270px;
  border-right: 1px solid #E4E4E4;
  padding: 10px;
}
.bannerProductsStrength .strengths-card:last-of-type{
  border: none;
}
.bannerProductsStrength .strengths-logo{
  width: 80px;
  height: 80px;
}

.bannerProductsStrength .strengths-card-title{
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  letter-spacing: 0.06em;
  color: rgba(169, 1, 1, 0.95);

}

.bannerProductsStrength .strengths-card-paragraph{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #4D4D4D;

}

@media (max-width: 1000px) {
  .bannerProductsStrength .strengths-card{
    border: none;
  }
}

@media (min-width: 1500px) {
  .bannerProductsStrength .strengths-logo{
    height: 100px;
    width: 100px;
  }
  .bannerProductsStrength .strengths-card{
    height: 300px;
    width: 300px;
  }
  .strengths{
    max-width: 1200px;
  }
  .bannerProductsStrength .strengths-card-title{
    font-size: 26px;
  }
  .bannerProductsStrength .strengths-card-paragraph{
    font-size: 20px;
  }
}