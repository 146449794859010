.bannertitles-titles {
    padding-top: 6%;
    padding-bottom: 3%;
    max-width: 600px;
    text-align: center;
    margin: auto;
  }
  
  #equipo .bannertitles-titles{
    max-width: 870px;
  }
  .bannertitles-subtitle {  
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #FF5B68;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
  }

  
  .bannertitles-title{
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;  
    text-align: center;
    letter-spacing: 0.06em;
    color: #404972;
    margin-bottom: 40px;
    line-height: 42px;
    text-align: center;
  }
  
  #comparativaProductos .bannertitles-titles {
    max-width: 780px;
  }
  #comparativaProductos .bannertitles-titles .bannertitles-title{
    
    font-weight: 700;
    font-family: 'Ubuntu';
    font-style: normal;
    font-size: 32px;
    line-height: 42px;
  }
  @media (max-width: 800px) {
    .bannertitles-subtitle{
      font-size: 14px;
    }
    .bannertitles-title{
      font-size: 20px;
      margin-left: 33px;
      margin-right: 33px;      
      line-height: 135%;
    }

    #Product .bannertitles-title, #comparativaProductos .bannertitles-titles .bannertitles-title{
      margin: auto;      
      font-size: 20px;
      line-height: 135%;
    }
    #Product .datasheet .bannertitles-subtitle{
      font-size: 14px;
      line-height: 30px;
    }
  }