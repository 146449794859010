header {
  width: 100%;
}
/*.nav-area {
  width: 100%;
  display: flex;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  height: 85px;
  background: linear-gradient(
    106.12deg,
    rgba(169, 1, 1, 0.95) 22.42%,
    #ba000e 77.58%
  );
  backdrop-filter: blur(20px);
  color: white;
  padding: 0;
  z-index: 100;
  position: fixed;
  top: 0;
  width: 100%;
  border-radius: 0;
  padding-left: 160px;
  padding-right: 160px;
  display: flex;
  justify-content: space-between;
}*/
/* ... Otro código existente ... */

/* Ajustes generales para el navbar */
.nav-area {
  background: linear-gradient(106.12deg, rgba(169, 1, 1, 0.95) 22.42%, #ba000e 77.58%);
  backdrop-filter: blur(20px);
  color: white;
  padding: 0;
  z-index: 100;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center; /* Alinea verticalmente los elementos */
  justify-content: space-between;
}

/* Estilos para pantallas grandes */
@media (min-width: 911px) {
  .nav-area {
    padding: 0 160px;
  }

  .menus {
    display: flex;
  }

  #btn-hamb {
    display: none;
  }

  #nav-collapse {
    display: none;
  }

  /* Ajustes para el botón de cambio de idioma */
  .language-button {
    display: inline-block;
  }
}

/* Ajustes para pantallas pequeñas (max-width: 910px) */
@media (max-width: 910px) {
  .nav-area {
    padding: 24px 20px;
  }

  .menus {
    display: none;
  }

  #btn-hamb {
    display: block;
    width: 32px;
    height: 32px;
  }

  #nav-collapse {
    display: none;
  }

  .nav-collapse-open #nav-collapse {
    display: block;
  }

  /* Ajustes para el botón de cambio de idioma */
  .language-button {
    display: inline-block;
  }
}

.branNav {
  max-width: 153px; /* Cambiado a max-width */
  height: auto; /* Cambiado a auto */
}

.menus {
  display: flex;
  list-style: none;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #fcfcfc;
  align-items: center;
}

.menu-items {
  position: relative;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
  font-weight: 400;
  padding: 0;
  margin: 0;
  padding-left: 24px;
}

.menu-items button {
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}
.menu-items button.contact-btn {
  width: 170px;
}

.menu-items button.nivel1,
.menu-items a.nivel1 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #fcfcfc;
  width: 140px;
}
.menu-items a.nivel1 {
  padding: 0;
}
/* segundo nivel menu */
.menu-items a,
.menu-items button.nivel2 {
  color: #fcfcfc;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.01em;
  text-align: left;
}
button.nivel1 {
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
}
button.nivel2,
.dropdown .menu-items .nivel2 {
  height: 53px;
  padding-left: 24px;
  padding-right: 0;
  display: flex;
  align-items: center;
}
.menu-items a.nivel3 {
  height: 50px;
  display: flex;
  align-items: center;
}

.menu-items a:hover,
.menu-items button:hover {
  background: #9b0800;
}

button.nivel1:hover,
.menu-items a.nivel1:hover {
  background-color: transparent;
}
.arrow::after {
  content: "";
  display: inline-block;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  list-style: none;
  z-index: 9999;
  background-color: #b7030e;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: none;
  box-shadow: 0px 16px 40px rgba(117, 117, 117, 0.18);
  padding-bottom: 5px;
  width: 190px;
  position: absolute;
  left: 3%;
  top: 85px;
}

.dropdown.show {
  display: block;
}

.dropdown.dropdown-submenu {
  position: absolute;
  left: 100%;
  top: 0;
}

/* MOBILE  */
#btn-hamb {
  display: none;
  width: 0;
  height: 0;
  padding: 4px;
  border-radius: 5px;
}
#btn-hamb:active {
  background: linear-gradient(
    106.12deg,
    rgba(169, 1, 1, 0.95) 22.42%,
    #ba000e 77.58%
  );
  /* Pressed */

  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#menu-mobile-bh-content {
  padding: 0 !important;
}

.css-15v22id-MuiAccordionDetails-root {
  padding: 0px 8px 0px !important;

}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  display: none;
}
#nav-collapse {
  position: absolute;
  top: 85px;
  left: 0;
  width: 100%;
  background-color: #b7030e;
  border-radius: 0;
  /* border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px; */
  background-color: #b7030e;
  padding-bottom: 16px;
  width: 100%;
  display: none;
}
.nav-collapse-close {
  display: none !important;
}
.nav-collapse-open {
  display: block;
}
#nav-collapse .collapseItem1 {
  background-color: #b7030e;
  box-shadow: none;
}
#panel2-bh-header,
#panel1-bh-header {
  height: 48px !important;
}
/* btn collapse */
#btnNavCollapse {
}
#btnNavCollapse button.contact-btn {
  width: 100%;
  border-radius: 0;
  background-color: #b7030e !important;
  text-align: left;
  padding-left: 16px;
  height: 48px;
}
.collapseItem1 {
  padding-left: 8px;
}
.collapseItem1 a {
  padding-left: 16px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.06em;
  color: #fcfcfc;
  height: 48px;
  display: flex;
  align-items: center;
}
p.titleAccordionCollapse {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 35px;
  letter-spacing: 0.06em;
  color: #fcfcfc;
}
.navbar-collapseButton,
#noisen {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
  letter-spacing: 0.01em;
}
.css-15v22id-MuiAccordionDetails-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#nav-collapse .MuiAccordionSummary-expandIconWrapper {
  color: rgb(255, 255, 255);
}

@media (max-width: 910px) {
  #nav-collapse {
    display: block;
  }
  #btn-hamb {
    display: block;
    width: 32px;
    height: 32px;
  }
  .nav-collapse {
    display: none;
  }
  .nav-area {
    padding: 24px 20px;
  }
}

@media (min-width: 910px) and (max-width: 1000px) {
  .nav-area {
    padding: 24px 20px;
  }
}
@media (min-width: 1000px) and (max-width: 1200px) {
  .nav-area {
    padding: 24px 50px;
  }
}
@media (min-width: 1200px) and (max-width: 1350px) {
  .nav-area {
    padding: 24px 100px;
  }
}
