.banner-empresas{
    text-align: -webkit-center;
    /* padding-top: 100px; */
}

.banner-empresas .clientes{
    display: flex;
    justify-content: center;
    gap: 50px;
    flex-wrap: wrap;
    margin: 0 160px;
    max-width: 1800px;
}
.banner-empresas .clientes img{
    max-width: 200px;
    max-height: 100px;
    object-fit: contain;
    
}

.clientes{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(100px, auto);
}


@media (max-width: 500px){
    .banner-empresas .clientes img :not(.socioEstragicoIA){
        display: none;
        width: 0;
        height: 0;
    }
    .banner-empresas .clientes{
        margin: 0 16px;
        gap: 40px;
    }
    .banner-empresas .clientes .imgMobile{
        display: block ;
        height: 32px;
    }
    .banner-empresas .clientes .shell{
        width: 28px;
    }
    .banner-empresas .clientes .accenture {
        width: 120px;
    }
    .banner-empresas .clientes .velos {
        width: 95px;
    }
    .banner-empresas .clientes .austral {
        width: 104px;
    }
    .banner-empresas .clientes .ypf {
        width: 32px;
    }
    .banner-empresas .clientes .asp{
        width: 116px;
    }


}
@media (max-width:700px){
    .banner-empresas .clientes{
        margin: 0 16px;
    }
    .banner-empresas .clientes img :not(.socioEstragicoIA){
        display: none;
        width: 0;
        height: 0;
    }
    .banner-empresas .clientes .imgMobile{
        display: block ;
        height: 40px;
    }
    .banner-empresas .clientes .imgMobile{
        display: block ;
        height: 32px;
    }
    .banner-empresas .clientes .shell{
        width: 35px;
    }
    .banner-empresas .clientes .accenture {
        width: 150px;
    }
    .banner-empresas .clientes .velos {
        width: 120px;
    }
    .banner-empresas .clientes .austral {
        width: 130px;
    }
    .banner-empresas .clientes .ypf {
        width: 40px;
    }
    .banner-empresas .clientes .asp{
        width: 145px;
    }
}
@media (max-width:850px){
    .banner-empresas .clientes{
        margin: 0 64px;
    }
}


@media (max-width:1100px){
    .banner-empresas .clientes{
        margin-left: 50px;
        margin-right: 50px;
    }   
}