/* GENERAL */
.swiper-pagination-bullet-active { 
    background-color: #af0a0d !important; 
} 
.swiper-button-next:after , .swiper-button-prev:after{
    font-size: 30px !important;
}
.swiper-button-prev{
    left: -10px;
}
.swiper-button-next{
    left: -10px;
}
.slide{
    overflow: hidden;
    text-align: center;
    background-color: #FCFCFC;
    box-shadow: 0px 16px 40px rgba(239, 239, 239, 0.25);
    border-radius: 40px;
}



/* TEAM */
#swiperTeam{    
    margin: 0px 30px 110px 30px; /* 0px 110px 110px 110px; */
    padding: 15px;   
    padding-bottom: 30px;
}
#swiperTeam .swiper-pagination-horizontal { 
    top: auto;
}
#equipo{
    display: block;
    background: linear-gradient(180deg, rgba(218, 220, 222, 0) 7.54%, rgba(218, 220, 222, 0.4) 32.59%, rgba(218, 220, 222, 0.5) 63.98%, rgba(218, 220, 222, 0) 100%);
}
#swiperTeam .swiper-button-next, #swiperProductsCompare .swiper-button-next, #swiperTestimonios .swiper-button-next ,#swiperGallery .swiper-button-next ,#swiperTeam .swiperImgProduct, #swiperImgProduct .swiper-button-next{
    right: var(--swiper-navigation-sides-offset,1px);
    color: #848484;
}
#swiperTeam .swiper-button-prev, #swiperProductsCompare .swiper-button-prev, #swiperTestimonios .swiper-button-prev ,#swiperGallery .swiper-button-prev ,#swiperTeam .swiper-button-prev , #swiperGalleryProduct .swiper-button-prev, #swiperImgProduct .swiper-button-prev{
    left: var(--swiper-navigation-sides-offset,1px);
    color: #848484;
}

/* GALLERY */
#swiperGallery{ 
    padding: 50px 30px;
    
} 
#swiperGallery .gallery{
    width: 800px;
}
#swiperGallery img{
    padding: 0px;
    border-radius: 16px;
    margin: auto;
    height: 382px;
    width: 575px;    
    object-fit: cover;
}
#swiperGallery .swiper-pagination-horizontal { 
    top: 450px;
}
#swiperGallery .swiper-button-prev , #swiperGallery .swiper-button-next{
    /* display: none; */
}
/* OPCION DE GALERIA CON MARGENES */
/*
#swiperGallery .swiper-button-prev , #swiperGallery .swiper-button-next{
    background: #eff0f1;
    width: 50px;
    height: 100%;
    top: 23px;
}
#swiperGallery .swiper-button-prev{
    left: -5px;
}
#swiperGallery .swiper-button-next{
    right: -5px;
}
*/

/* IMAGE PRODUCT */
#swiperImgProduct{
    max-width: 90vw;
    /*height: 90vw;*/
}
.productsContainer{
    margin-top: -100px;
}
.productsContainer .products{
    width: 655px;
    border: 1px solid #D4D4D4;;
}
#swiperProductsCompare .CardProductCompare{
    max-width: 750px;
    margin: auto;
    height: auto;
}
#swiperProductsCompare .CardProductCompare .swiper-wrapper{
    justify-content: space-around !important;
}

#swiperImgProduct img{
    max-height: 400px;
    max-width: 425px;
    margin: auto;
}

/* TESTIMONIOS */
#swiperTestimonios{
    margin: 0px 50px;
}
.testimoniosContainer .sliderTestimonios{
    background-color:transparent;
    box-shadow: 0 0 0 rgba(255, 255, 255, 0);
    margin-top: 30px;
}
.testimoniosContainer .rec-arrow, .teamContainer .rec-arrow, .galleryContainer .rec-arrow{
    color: #0b2b51;
    box-sizing: border-box;
    text-align: center;
    width: 56px;
    height: 56px;
    background: #ffffff45;
    border: 1px solid #d2d2d245;
    border-radius: 100px;
    text-align: center;
    margin: 30px;
}

.testimoniosContainer .rec-dot, .teamContainer .rec-dot, .galleryContainer .rec-dot{
    background: #ffffffaf;
    border: 1px solid #d2d2d2;
}
.testimoniosContainer .rec-dot_active{
    background: #0f61cd;
    border: 1px solid #d2d2d2;
}

@media (max-width: 640px){
    #swiperImgProduct img{
        max-width: 250px;
    }
    #swiperGallery  img{
        padding: 0;
        border-radius: 16px;
        margin-left: 0;
        height: 342px;
        width: 575px;    
        object-fit: cover;
    }   
    #swiperGallery .swiper-pagination-horizontal {
        top: 420px;
    }

    #swiperTeam{
        margin-left: 5px;
        margin-right: 5px;
        padding: 15px;
        padding-bottom: 25px;
    }
    #swiperTeam .swiper-pagination-horizontal{
        top: auto;
    }
    #swiperTestimonios{
        margin: 0 10px;
        padding: 20px;
    }

}
@media (min-width: 500px){
    #swiperTestimonios{
        margin: 0 50px;
        padding: 0;
    }
}
@media (max-width: 1200px){
    #swiperGallery img{
        height: auto;
        width: auto;
    }
    #swiperGallery .swiper-pagination-horizontal{
        top: auto;
    }
}
@media (min-width: 1200px) {
    /* TEAM */
    #swiperTeam{    
        margin: 0px 110px 110px 110px;
        padding: 30px;    
    }
    #swiperImgProduct{
        width: 40vw;
        height: 40vw;
    }
}
@media (min-width: 1400px) {
    #swiperTeam .swiper-pagination-horizontal{
        display: none;
    }
}
@media (min-width: 2000px) {
    #swiperProductsCompare{
        margin: 0 295px;
        padding: 0 50px;
    }
    #swiperProductsCompare .products{
    }
    
}