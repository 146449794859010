.bannerMoreInfo{
    margin: auto;
    padding: 80px 16px;
}
.bannerMoreInfo span{
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 40px; 
    background-image: url("../../../public/assets/bg-banner-more-info.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 116px;
    margin-top: -116px;
    opacity: 0.5;

}
.bannerMoreInfo div{
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 16px;
    gap: 24px;
    border-radius: 40px;  
    background-color: #9B0800;
   /* background-image: url("../../../public/assets/bg-banner-more-info.png"); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 116px;
}

.bannerMoreInfo h2{
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 135%;display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.06em;
    color: #FCFCFC;
}
.bannerMoreInfo button{
    background: #8D8D8D;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.01em;
    text-align: center;
    background: linear-gradient(106.12deg, rgba(169, 1, 1, 0.95) 22.42%, #BA000E 77.58%) !important;
    width: 167px;
    height: 48px;
    border-radius: 40px;
    line-height: 20px;
    color: #E4E4E4;
    z-index: 10;
}
.bannerMoreInfo button:hover{
    background: linear-gradient(106.12deg, rgba(169, 1, 1, 0.95) 22.42%, #BA000E 77.58%);
    box-shadow: 0px 16px 40px rgba(181, 113, 113, 0.18);
}
.bannerMoreInfo button:active{
    background: linear-gradient(106.12deg, rgba(169, 1, 1, 0.95) 22.42%, #BA000E 77.58%);
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* a partir del 800 se rompe */
@media (max-width: 800px) {
    .bannerMoreInfo div{
        height: 200px;
    }
    .bannerMoreInfo span {
        height: 200px;
        margin-top: -200px;
        background-image: url("../../../public/assets/bg-banner-more-info-mobile.png");
    }
    .bannerMoreInfo button{
        width: 217px;
        height: 50px;
    }
}
@media (min-width: 440px){
    .bannerMoreInfo div{
        height: 160px;
    }    
    .bannerMoreInfo span {
        height: 160px;
        margin-top: -160px;
    }

}

@media (min-width: 600px) {
    .bannerMoreInfo div{
        height: 170px;
    }    
    .bannerMoreInfo span {
        height: 170px;
        margin-top: -170px;
    }
    .bannerMoreInfo{
        margin: 120px auto;
    }
    
    .bannerMoreInfo h2{
        font-family: 'Ubuntu';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 31px;
        letter-spacing: 0.06em;
        color: #FCFCFC;
        
    }
    .bannerMoreInfo button{
        width: 215px;
        height: 52px;
        font-weight: 700;
        background: #B3261E !important;
        border-radius: 24px;
        font-family: 'Ubuntu';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.01em;
        color: #FCFCFC;
    }
}



@media (min-width: 800px) {
    .bannerMoreInfo div{
        height: 118px;
        max-width: 868px;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        border-radius: 40px;
        padding: 34px 60px;
        gap: 40px;
    }

    .bannerMoreInfo span{
        max-width: 868px;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        border-radius: 40px;
        height: 118px;
        margin-top: -118px;

    }
}
@media (min-width: 1800px) {
    .bannerMoreInfo{
        margin: 20vh;
    }
    .bannerMoreInfo div ,  .bannerMoreInfo span{
        max-width: 1200px;
    }
    .bannerMoreInfo button{
        font-size: 22px;
        width: 240px;
        height: 58px;
    }
    .bannerMoreInfo h2{
        font-size: 26px;
    }
}