:root{
  overflow-x: hidden;
}
::-webkit-scrollbar{
  display: none;
}
*{
  box-sizing: border-box;
}.expandContainer {
  height: 300px;
  overflow-y: scroll;
}

/* Estilos para el scrollbar */
.expandContainer::-webkit-scrollbar {
  width: 6px; /* Ancho del scrollbar */
  display: block; /* Asegura que el scrollbar esté visible */
}

.expandContainer::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color de fondo del track (la "pista" por donde se desplaza el scrollbar) */
}

.expandContainer::-webkit-scrollbar-thumb {
  background-color: #c2bdbd; /* Color del thumb (la "barra" que se puede arrastrar) */
  border-radius: 4px; /* Radio de borde del thumb */
  
  
}

.expandContainer::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color del thumb al pasar el mouse sobre él */
}
/* CLASES PARA CAMBIAR FONDOS DE PAGINA */
.backgroundImage{
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover
}
/*  max 1600px top */
/*  min 1600px home - 2 */
.bgImageHome{
  background-position: top;
  background-size: contain;
  background-image: url("../public/assets/Home.png");
}


.bgImageProducts{
  background-image: url("../public/assets/bg-product-1.png");
  background-position: bottom;
  background-size: 2000px;
}
.backgroundImage-inversa{
  background-image: url("../public/assets/bg-product-1-inversa.png");
  background-position: bottom;
  background-size: 2000px;
}


/* ANIMACIONES DE CAMBIO DE PAGINA */
#AboutUs , #Product , #Home , #Servicios{
  opacity: 1;
  transform: translateY(0);
  animation: fadeInOnLoad 1s ease-in  1;
  overflow:hidden;
}

@keyframes fadeInOnLoad {
  0%   {opacity: 0;
    transform: translateY(-50%);}
  100% {opacity: 1;
    transform: translateY(50%);}
}

.App {
  text-align: center;
}

@media (max-width:650px){
  .bgImageHome{
    background-size: cover;
    background-image: url("../public/assets/bg-home-mobile.png");
  }
  .bgImageProducts{
    background-image: none;
  }
  .bgImageProducts , .backgroundImage-inversa{
    background-image: none;
  }
}
@media (min-width:1300px){
  .bgImageHome{    
  background-size: cover;
  }
}
@media (min-width:1300px){
  .bgImageHome{    
  background-size: cover;
  }
}
@media (min-width:650px) and (max-width:1600px){
  .bgImageHome{
    background-image: url("../public/assets/Home.png");
  }
}
@media (min-width:1600px){
  .bgImageHome{
    background-image: url("../public/assets/Home-lg.png");
  }
}