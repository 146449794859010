@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Open+Sans:wght@400;600&family=Plus+Jakarta+Sans&family=Ubuntu:wght@400;500;700&display=swap');


.footer{
    background: linear-gradient(106.12deg, rgba(169, 1, 1, 0.95) 22.42%, #BA000E 77.58%);
    padding: 20px 160px;
    width: 100%;
}
.socialMedia .icons img{
}

.footer li, .footer .socialMedia p{
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #FCFCFC;
}
.footer .footerTitle, .footer .socialMedia .footerTitle{
    margin-bottom: 16px;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #FCFCFC;

}
.footer p.footerTitle , .footer .socialMedia .footerTitle{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.06em;
    color: #FCFCFC;
}
.footer .aboutUs ul li a.navItem, .footer .contacData ul li{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.06em;
    color: #FCFCFC;
}
.footer .aboutUs, .footer .contacData {
    margin-bottom: 32px;
}

.footer .aboutUs ul, .footer .contacData ul{
    /*font-size: 12px;
    
    line-height: 20px;
    */
    display: flex;
    flex-direction: column;
    justify-content: space-between;    
    height: 112px;
}
.footer .icons{
    display: flex;
    gap: 24px;
    margin-bottom: 32px;
}
.footer .brand{
    max-width: 330px;
}
.footer .brand div{
    height: 40px;
    width: 130px;
    background-image: url("../../../public/assets/logo-mecantronic-footer-mobile.png");
    background-size: contain;
    background-repeat: no-repeat;
}
.footer .brand div{
    margin-bottom: 24px;
}
.footer .brand p{
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    font-family: 'Open Sans';
    font-style: normal;
    letter-spacing: 0.06em;
    color: #FCFCFC;
}
.footer .aboutUs{
    width: 50%;
}
.footer .contacData{
    width: 50%;
}

.footer .socialMedia .footerSubTitle{
    margin-bottom: 16px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.06em;
    color: #FCFCFC;
}
.footer > span:first-child{
    border-bottom: 1px solid #b65960;
}

.footer .copyrights{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}
.footer .copyrights p:first-of-type {
   /* margin-top: 24px; */
}
.footer .copyrights p{
   /* margin-bottom: 16px; */
}
.footer .copyrights p:last-child{
    margin-bottom: 0px;
}

.footer .icons{
    
}
.footer .icons img{
    border: 1px white solid;
    border-radius: 50%;
    width: 40px;
    padding: 8px;
}


/* revisar si es correcta este tamaño break */
@media (max-width: 725px){
    .footer{
        padding: 16px;
        padding-top: 24px;
    }
    .footer .brand div{
        height: 32px;
    }
    .footer p.footerTitle, .footer .socialMedia .footerTitle{
        font-family: 'Ubuntu';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #FCFCFC;
    }
    .footer .aboutUs ul li a.navItem{
        font-family: 'Ubuntu';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #FCFCFC;
    }
    .footer .contacData ul li{
        font-family: 'Ubuntu';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #FCFCFC;
    }
    .footer .socialMedia .footerSubTitle{
        font-family: 'Ubuntu';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #FCFCFC;
    }
    .footer .icons {
        margin-bottom: 24px;
    }
    .footer .icons img{
        width: 32px;
    }
    .footer .copyrights p{
        font-family: 'Ubuntu';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #FCFCFC;
    }
    .footer .copyrights p:first-of-type{
        margin-top: 24px;
    }
    .footer .copyrights p:last-of-type{
        margin-top: 16px;
    }
    
}

@media (min-width: 725px) {
    .footer{
        padding: 20px;
    }
    .footer .icons{
        justify-content: center;
    }
    .footer span:first-of-type{
        display: flex;
        justify-content: space-evenly;
        gap: 15px;
    }
    .footer .brand{
        width: 100px;
    }    
    .footer .brand div{
        height: 100px;
        width: 100px;
        background-image: url("../../../public/assets/logo-mecantronic-footer.png");
        background-size: contain;
        background-repeat: no-repeat;
    }
    .footer .socialMedia{
        margin-top: 32px; 
    }
    .footer .copyrights{
        display: flex;
        justify-content: space-between;
    }
    .footer .copyrights p{ 
        /*margin: 24px 0 0 16px ; */
    }

  }
  @media (min-width: 1000px) { 
    .footer .socialMedia{
        margin-top: 0px; 
    } 
    .footer .icons{
        justify-content: start;
    }
    .footer .brand{
        width: 300px;
    }  
    .footer .brand div{        
        height: 144px;
        width: 144px;
    }  
    .footer span{
        width: 100%;
        max-width: 1050px;
        margin: auto;
    }
  }
  @media (min-width: 1500px) {    
    .footer span{
        width: 100%;
        max-width: 1300px;
        margin: auto;
    }
    .footer .footerTitle, .footer .socialMedia .footerTitle{
        margin-bottom: 19px;    
    }
    .footer .socialMedia p, .footer li{
        font-size: 18px;
    }
    .footer .socialMedia .footerSubTitle{
        font-size: 18px;
    }
    .footer .copyrights{
        font-size: 16px;
    }
    .footer .icons img{
      /*  width: 37px; */
    }
  }